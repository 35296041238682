
import { defineComponent } from 'vue'
import { AuthRegister } from '@bd/components'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Registration',
  components: { AuthRegister },
  setup() {
    const router = useRouter()
    const onRegister = () => router.push({ path: '/' })

    return {
      onRegister,
    }
  },
})
